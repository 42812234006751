import React, { useState, useEffect } from "react";
import { Table, Input, Button } from "antd";
import { db } from '../../../db/db';
import UsersModal from "./UsersModal";

function UsersTable() {
    const [data, setData] = useState([]);
    const [info, setInfo] = useState({});
    const [open, setOpen] = useState(false);
    const [searchedText, setSearchedText] = useState("");

    const usersCol = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            filteredValue: [searchedText],
            onFilter: (value, record) => {
                return String(record.firstName)
                    .toLowerCase()
                    .includes(value.toLowerCase()) ||
                    String(record.lastName)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.email)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.organization)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.phone)
                        .toLowerCase()
                        .includes(value.toLowerCase());
            },
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Tier',
            dataIndex: 'tier',
            key: 'tier',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            key: 'organization',
        },
    ];

    const getUsersData = async () => {
        const usersArr = [];
        await db.collection('users').get()
            .then(result => result.docs)
            .then(docs => docs.map(doc => doc.data()))
            .then((docs) => {
                docs.forEach(doc => {
                    usersArr.push({
                        email: doc.email ? doc.email : '',
                        firstName: doc.firstName ? doc.firstName : '',
                        lastName: doc.lastName ? doc.lastName : '',
                        tier: doc.tier ? doc.tier : null,
                        phone: doc.phone ? doc.phone : '',
                        organization: doc.organization ? doc.organization : '',
                        googleCalendarsEnabled: doc.googleCalendarsEnabled ? doc.googleCalendarsEnabled : false,
                        loggedInDevices: doc.loggedInDevices ? doc.loggedInDevices : 0,
                        allTrackedEvents: doc.allTrackedEvents ? doc.allTrackedEvents : [],
                        currentNotifications: doc.currentNotifications ? doc.currentNotifications : [],
                        expoPushTokens: doc.expoPushTokens ? doc.expoPushTokens : [],
                        legislationNotes: doc.legislationNotes ? doc.legislationNotes : [],
                        trackedCommitteeNames: doc.trackedCommitteeNames ? doc.trackedCommitteeNames : [],
                        trackedCommittees: doc.trackedCommittees ? doc.trackedCommittees : [],
                        trackedLegislation: doc.trackedLegislation ? doc.trackedLegislation : [],
                        trackedLegislationTitles: doc.trackedLegislationTitles ? doc.trackedLegislationTitles : [],
                        trackedStatAgs: doc.trackedStatAgs ? doc.trackedStatAgs : [],
                        trackedWords: doc.trackedWords ? doc.trackedWords : [],
                    });
                });

                setData(usersArr);
            })
    };

    useEffect(() => {
        getUsersData();
    }, []);

    const rowClicked = (record) => {
        setInfo(record);
        setOpen(true);
    };

    const onSubmit = async (saveData) => {
        try {
            db.collection('users').doc(saveData.email).update({
                firstName: saveData.firstName ? saveData.firstName : null,
                lastName: saveData.lastName ? saveData.lastName : null,
                tier: saveData.tier ? saveData.tier : null,
                phone: saveData.phone ? saveData.phone : null,
                organization: saveData.organization ? saveData.organization : null,
                loggedInDevices: saveData.loggedInDevices ? saveData.loggedInDevices : null,
            })
                .then(() => {
                    setInfo({})
                    getUsersData();
                    setOpen(false);
                })
        } catch (err) {
            console.log(err);
        }
    };

    const onDelete = async () => {
        db.collection('users').doc(info.email).delete().then(() => {
            alert("User successfully deleted!");
            setInfo({})
            getUsersData();
            setOpen(false);
        }).catch((error) => {
            alert("Error removing user!");
        });
    };

    return (
        <div>
            <Input.Search
                placeholder='Search Users'
                style={{ marginBottom: 8 }}
                onSearch={(value) => setSearchedText(value)}
                onChange={(e) => setSearchedText(e.target.value)}
            />
            <Table
                onRow={(record) => ({
                    onClick: () => rowClicked(record),
                })}
                bordered
                size={'small'}
                rowKey="email"
                columns={usersCol}
                dataSource={data}
                pagination={{
                    position: ["bottomCenter"],
                    hideOnSinglePage: true,
                }}
                scroll={{ y: 500 }}
                footer={() =>
                    <div>
                        <UsersModal
                            info={info}
                            setInfo={setInfo}
                            onSubmit={onSubmit}
                            onDelete={onDelete}
                            open={open}
                            setOpen={setOpen}
                        />
                    </div>
                }
            />
        </div>
    );
}

export default UsersTable;