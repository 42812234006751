import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Input, Select, DatePicker } from 'antd';
import '../../../App.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const { Option } = Select;

function WebCalModal({ info, tableType, onSubmit, open, setOpen, committeeData }) {
    const [committeeOptions, setCommitteeOptions] = useState()
    const [pageUrl, setPageUrl] = useState()
    const [form] = Form.useForm();

    const chamberValues = {
        1: 'House',
        2: 'Senate',
    }
    const chamberOptions = [
        {
            value: 1,
            label: "House"
        },
        {
            value: 2,
            label: "Senate"
        },
    ]

    useEffect(() => {
        const committeeArr = [];
        for (let i = 0; i < committeeData?.length; i += 1) {
            committeeArr.push(<Option key={committeeData[i].id} value={`${committeeData[i].name} ${committeeData[i].chamber}`}>{committeeData[i].name} ({chamberValues[committeeData[i].chamber]})</Option>)
        }
        setCommitteeOptions(committeeArr)
    }, [committeeData])

    useEffect(() => {
        if (info.subject) {
            if (info.chamber && info.chamber !== null) {
                setPageUrl(`https://www.legis.ga.gov/schedule/${chamberValues[info.chamber].toLowerCase()}/${info.id}`)
            }
            setOpen(true)
        }
    }, [info]);

    useEffect(() => {
        form.setFieldsValue(info)
    }, [info, form]);

    const showModal = () => {
        form.setFieldsValue({
            id: `${new Date().getTime()}`,
            subject: null,
            start: dayjs().utc().tz('America/New_York'),
            location: null,
            chamber: null,
            livestreamUrl: null,
            committees: [],
            billsResolutions: null,
            agendaLink: null,
        })
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
        setPageUrl()
    };

    const onFinish = (data) => {
        onSubmit(data);
        setPageUrl()
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                New Entry
            </Button>
            <Modal
                style={{ top: 10 }}
                open={open}
                title={`Edit ${tableType}`}
                onCancel={handleCancel}
                width={700}
                footer={null}
                maskClosable={false}
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="Web Page">
                            {pageUrl ? <a href={pageUrl} target="_blank">Go to Page</a> : null}
                        </Form.Item>
                        <Form.Item label="ID" name='id'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label="Chamber" name='chamber'>
                            <Select
                                options={chamberOptions}
                            />
                        </Form.Item>
                        <Form.Item label="Subject" name='subject'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Date/Time" name='start' getValueProps={(i) => ({ value: dayjs(i).utc().tz('America/New_York') })}>
                            <DatePicker
                                allowClear={false}
                                itialValue={dayjs().utc().tz('America/New_York')}
                                showTime={{ use12Hours: true, format: "hh:mm a" }}
                                format="YYYY-MM-DD hh:mm a"
                            />
                        </Form.Item>
                        <Form.Item label="Location" name='location'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="L-Link" name='livestreamUrl'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Committees" name='committees'>
                            <Select
                                mode="multiple"
                                placeholder="Select a Committee"
                                // onChange={onCommitteeChange}
                                allowClear
                            >
                                {committeeOptions}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Bills/Res" name='billsResolutions'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Agenda Link" name='agendaLink'>
                            <Input />
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 120 }}>
                            <Button block type='primary' htmlType='submit'>
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default WebCalModal
