import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Input, InputNumber, Select, DatePicker, Upload, Popconfirm } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../App.css'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { storage } from '../../../db/db';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

dayjs.extend(utc);
dayjs.extend(timezone);

function CalendarsModal({ info, setInfo, onSubmit, open, setOpen, checkDays, onDelete }) {
    const [form] = Form.useForm();
    const [isDisabled, setIsDisabled] = useState(true);
    const [isNewEntry, setIsNewEntry] = useState(false);
    const [warning, setWarning] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (info.title && !isNewEntry) {
            setIsDisabled(true)
            setOpen(true)
        }
    }, [info])

    // 

    useEffect(() => {
        if (info) {
            form.setFieldsValue({
                ...info,
                sessionDay: info.date ? checkDays(dayjs(info.date)) : null
            })
        }
    }, [info, form])

    const showModal = () => {
        setIsDisabled(false)
        setIsNewEntry(true)
        form.setFieldsValue({
            title: null,
            date: dayjs().utc().tz('America/New_York'),
            sessionDay: 1,
            billsResolutions: null,
            chamber: null,
            type: null,
            attachmentLink: null,
        })
        setOpen(true);
    };

    const handleCancel = () => {
        setInfo({})
        setIsNewEntry(false)
        setWarning(false)
        setOpen(false);
    };

    const onFinish = (data) => {
        if (data.sessionDay &&
            data.title !== null &&
            data.chamber !== null &&
            data.type !== null
        ) {
            setWarning(false)
            onSubmit(data);
        } else {
            setWarning(true)
        }
    };

    const confirmDelete = (e) => {
        onDelete(info)
    };
    const cancelDelete = (e) => {
        return
    };

    const documentOptions = [
        {
            value: 1,
            label: 'General Calendar',
        },
        {
            value: 2,
            label: 'Rules Calendar',
        },
        {
            value: 4,
            label: 'Local Calendar',
        },
        {
            value: 8,
            label: 'Miscellaneous Calendar',
        },
        {
            value: 16,
            label: 'First Readers',
        },
        {
            value: 32,
            label: 'Daily Status',
        },
        {
            value: 512,
            label: 'Composite',
        },
    ]

    const handleFileUpload = async ({ file }) => {
        setIsUploading(true);
        const newFileList = [...fileList, { ...file, status: 'uploading' }];
        setFileList(newFileList);
        try {
            const storageRef = ref(storage, `calendars/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                null,
                (error) => {
                    console.error("Upload error:", error);
                    setIsUploading(false);
                    setFileList(fileList.map((f) => (f.uid === file.uid ? { ...f, status: 'error' } : f)));
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    form.setFieldsValue({ attachmentLink: downloadURL }); // Set the link in the form
                    setIsUploading(false);
                    setFileList(fileList.map((f) => (f.uid === file.uid ? { ...f, status: 'done' } : f)));
                }
            );
        } catch (error) {
            console.error("Error uploading file:", error);
            setIsUploading(false);
        }
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                New Entry
            </Button>
            <Modal
                open={open}
                title={'Edit Calendar'}
                onCancel={handleCancel}
                width={750}
                footer={null}
                maskClosable={false}
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="Calendar Name" name='title'>
                            <Input disabled={isDisabled} />
                        </Form.Item>
                        <Form.Item
                            label="Date"
                            name="date"
                            getValueProps={(i) => ({
                                value: dayjs(i).utc().tz('America/New_York'), // Convert UTC to EST for display
                            })}
                        >
                            <DatePicker
                                allowClear={false}
                                placeholder='Date'
                                format='YYYY-MM-DD'
                                placement={'bottomLeft'}
                                onChange={(e, x) => {
                                    if (x.length <= 10) {
                                        x = `${x} 01:00:00 AM`
                                    }
                                    const newDate = dayjs(x, 'YYYY-MM-DD hh:mm a').tz('America/New_York', true); // Treat the input as EST
                                    setInfo({
                                        ...form.getFieldsValue(),
                                        date: newDate.utc(), // Convert to UTC for storage
                                    })
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Session Day"
                            name="sessionDay"
                        >
                            <InputNumber min={1} max={41} />
                        </Form.Item>
                        <Form.Item
                            label="Bills/Res"
                            name='billsResolutions'
                            rules={[
                                {
                                    pattern: new RegExp(/^(HB|HR|SB|SR)\d+(,\s(HB|HR|SB|SR)\d+)*$/),
                                    message: "Wrong format!"
                                },
                            ]}
                        >
                            <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Item>
                        <Form.Item label="Document Type" name='type'>
                            <Select
                                options={documentOptions}
                            />
                        </Form.Item>
                        <Form.Item label="Chamber" name='chamber'>
                            <Select
                                options={[
                                    { value: 'House', label: 'House' },
                                    { value: 'Senate', label: 'Senate' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label="Attachment Link" name='attachmentLink'>
                            <Input placeholder="Enter Calendar link or upload below" />
                        </Form.Item>
                        <Form.Item label="Upload PDF">
                            <Upload
                                customRequest={handleFileUpload} // Handle file upload manually
                                accept=".pdf"
                                maxCount={1}
                                fileList={fileList} // Display the updated file list
                                onRemove={(file) => {
                                    setFileList(fileList.filter((f) => f.uid !== file.uid)); // Remove file from list
                                }}
                            >
                                <Button icon={<UploadOutlined />} loading={isUploading}>
                                    {isUploading ? 'Uploading...' : 'Upload PDF'}
                                </Button>
                            </Upload>
                        </Form.Item>
                        <div>
                            {warning ?
                                <p style={{ color: 'red', textAlign: 'center' }}>* Title, Date, Day, Type, and Chamber must be filled in!</p>
                                :
                                null
                            }
                        </div>
                        <Form.Item style={{ marginLeft: 160, marginTop: warning ? 20 : 50 }}>
                            <Button block type='primary' htmlType='submit' style={{ width: 200 }}>
                                Save Entry
                            </Button>
                            <Popconfirm
                                title="Delete the task"
                                description="Are you sure to delete this calendar?"
                                onConfirm={confirmDelete}
                                onCancel={cancelDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger style={{ marginLeft: 10, width: 200 }}>
                                    Delete Entry
                                </Button>
                            </Popconfirm>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default CalendarsModal

