import React, { useState } from 'react';
import { Form, Input, Button, Typography, Row, Col, Spin, Checkbox } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import Logo from '../assets/capitolIntel-removebg-preview.png';
import 'antd/dist/reset.css'; // Import Ant Design styles
import './SignUp.css'; // Import custom CSS for autofill and other styles

const { Title } = Typography;

const SignUp = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // State to manage loading indicator
    const [agreed, setAgreed] = useState(false); // Checkbox state

    const handleRegister = async (values) => {
        const { email, firstName, lastName, organization, password, confirmPassword, phoneNumber } = values;

        if (password !== confirmPassword) {
            alert('Passwords do not match.');
            return;
        }

        if (!agreed) {
            alert('You must agree to the User Agreement before registering.');
            return;
        }

        setLoading(true); // Start loading indicator

        try {
            // Send user data to server for validation and creation
            const response = await fetch('https://api.clarityinfo.org/register-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, firstName, lastName, organization, password, phoneNumber }),
            });

            const result = await response.json();
            if (result.success) {
                // Check if the user should be redirected to ExtraUsers page
                if (result.redirectToExtraUsers) {
                    navigate("/extraUsers", { state: { primaryEmail: email } });
                } else {
                    navigate("/registrationSuccess");
                }
            } else {
                alert(result.message || 'There was an error processing your request. Please try again later.');
            }
        } catch (error) {
            console.error('Error during registration:', error);
            alert('There was an error processing your request. Please try again later.');
        } finally {
            setLoading(false); // Stop loading indicator
        }
    };

    const handleCheckboxChange = (e) => setAgreed(e.target.checked);

    return (
        <Row justify="center" style={{ minHeight: '100vh', backgroundColor: '#121212', color: '#E2E2E2' }}>
            <Col xs={24} sm={16} md={12} lg={8} xl={6}>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <div className="logo-container sign-up-form">
                        <img src={Logo} alt="CAPITOL INTEL" className="logo" />
                    </div>
                    <Title level={3} style={{ color: '#FFFFFF', margin: '20px 0' }}>Finish Registering Your Account</Title>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleRegister}
                        className="form-container sign-up-form"
                        style={{ backgroundColor: '#232021', padding: '20px', borderRadius: '8px' }}
                    >
                        <Form.Item
                            name="email"
                            label={<span style={{ color: '#E2E2E2' }}>Email</span>}
                            rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                        >
                            <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            label={<span style={{ color: '#E2E2E2' }}>First Name</span>}
                            rules={[{ required: true, message: 'Please input your first name!' }]}
                        >
                            <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            label={<span style={{ color: '#E2E2E2' }}>Last Name</span>}
                            rules={[{ required: true, message: 'Please input your last name!' }]}
                        >
                            <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item
                            name="organization"
                            label={<span style={{ color: '#E2E2E2' }}>Organization</span>}
                        >
                            <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label={<span style={{ color: '#E2E2E2' }}>Password</span>}
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password
                                className="custom-input"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            label={<span style={{ color: '#E2E2E2' }}>Confirm Password</span>}
                            rules={[{ required: true, message: 'Please confirm your password!' }]}
                        >
                            <Input.Password
                                className="custom-input"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                        <Form.Item
                            name="phoneNumber"
                            label={<span style={{ color: '#E2E2E2' }}>Phone Number</span>}
                            rules={[{ required: true, message: 'Please input your phone number!' }]}
                        >
                            <Input className="custom-input" />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox checked={agreed} onChange={handleCheckboxChange}>
                                <span className="checkbox-label">I have read and agree to the </span>
                                <Button type="link" href="/user-agreement.pdf" target="_blank">User Agreement</Button>
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100%', backgroundColor: '#009090', borderColor: '#009090' }}
                                disabled={loading} // Disable button during loading
                            >
                                {loading ? <Spin /> : 'Register'} {/* Show spinner if loading */}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
        </Row>
    );
};

export default SignUp;