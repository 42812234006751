import React, { useState, useEffect } from "react";
import { Table, Select, Input } from "antd";
import { db } from '../../../db/db'
import CalendarsModal from './CalendarsModal'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function CalendarsTable() {
  const [data, setData] = useState()
  const [datesLoaded, setDatesLoaded] = useState(false);
  const [datesJson, setDatesJson] = useState();
  const [dateData, setDateData] = useState([{ label: '', value: '1' }])
  const [dateValue, setDateValue] = useState('0');
  const [info, setInfo] = useState({})
  const [dataFromDB, setDataFromDB] = useState();
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const documentOptions = {
    '1': 'General Calendar',
    '2': 'Rules Calendar',
    '4': 'Local Calendar',
    '8': 'Miscellaneous Calendar',
    '16': 'First Readers',
    '32': 'Daily Status',
    '512': 'Composite',
  }

  const calendarsCol = [
    {
      title: 'Calendar Name',
      dataIndex: 'title',
      width: 180,
      key: 'title',
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.title)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
          String(record.billsResolutions)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.chamber)
            .toLowerCase()
            .includes(value.toLowerCase())
      }
    },
    {
      title: 'Bills/Resolutions',
      key: 'billsResolutions',
      dataIndex: 'billsResolutions',
      width: 300,
      ellipsis: true,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      width: 80,
      render: item => documentOptions[`${item}`]
    },
    {
      title: 'Chamber',
      key: 'chamber',
      dataIndex: 'chamber',
      width: 50,
    },
  ];

  const getCalendarData = () => {
    if (!datesLoaded) {
      db.collection('calendarDays').doc('1031').get()
        .then(doc => {
          const items = doc.data()
          return items
        })
        .then(calendars => calendars.days.map(doc => ({
          date: doc.date,
          day: doc.day,
        })))
        .then(days => {
          setDatesJson(days)
          const datesArr = [];
          for (let i = 0; i < days.length; i += 1) {
            datesArr.push({
              label: `Day ${days[i].day}: ${days[i].date.substring(0, 10)}`,
              value: i,
            })
          }
          return datesArr
        }).then(dates => {
          setDateData(dates)
          setDatesLoaded(true)
        })
    } else {
      const allDataArr = [];
      db.collection('calendars').doc(new Date(datesJson[dateValue].date).toISOString()).get()
        .then(doc => {
          const item = doc.data()
          setDataFromDB(item.calendars)
          return item
        })
        .then(docs => {
          const docsArr = [];
          const calArr = docs.calendars
          for (let i = 0; i < calArr.length; i += 1) {
            docsArr.push({
              pageType: 'Calendar',
              chamber: calArr[i].chamber === 1 ? 'House' : 'Senate',
              title: calArr[i].title,
              date: calArr[i].date,
              billsResolutions: calArr[i].billsResolutions?.join(', '),
              type: calArr[i].type,
              attachmentLink: calArr[i].url,
              key: calArr[i].title,
            })
          }
          return docsArr
        })
        .then(calendars => {
          for (let i = 0; i < calendars.length; i += 1) {
            allDataArr.push(calendars[i])
          }
          setData(allDataArr)
        })
    }

  }

  useEffect(() => {
    getCalendarData()
  }, [datesLoaded, dateValue])


  const rowClicked = (e, record) => {
    const data = {
      ...record
    }
    setInfo(data)
  };

  const checkDays = (selectedDate) => {
    // Ensure the selected date is in the same format as the JSON dates
    const formattedSelectedDate = selectedDate.format("YYYY-MM-DD");

    // Iterate through datesJson to find a match
    const matchingDate = datesJson.find(item =>
      dayjs(item.date).format("YYYY-MM-DD") === formattedSelectedDate
    );

    if (matchingDate) {
      return matchingDate.day
    } else {
      // Optionally reset or log if no match is found
      console.log("No matching date found");
    }
  }

  const onSubmit = async (saveData) => {
    let updatedArr;
    const bills = saveData.billsResolutions?.replace(/\s/g, '').split(',')
    const updatedCalObj = {
      date: info.date ? info.date : null,
      chamber: saveData.chamber === 'House' ? 1 : 2,
      billsResolutions: bills ? bills : null,
      title: saveData.title ? saveData.title : null,
      type: saveData.type ? saveData.type : null,
      url: saveData.attachmentLink ? saveData.attachmentLink : null,
    }
    if (dataFromDB.find(o => o.title === saveData.title)) {
      // Updating an existing calendar
      updatedArr = dataFromDB.map(obj =>
        obj.title === saveData.title ? {
          ...obj,
          ...updatedCalObj
        } : obj
      );
      db.collection('allCalendars').doc(saveData.title).set(updatedCalObj, { merge: true })
      db.collection('calendars').doc(new Date(datesJson[dateValue].date).toISOString()).update({ calendars: updatedArr })
        .then(() => {
          getCalendarData();
          setOpen(false);
        })
    } else {
      // Adding a calendar
      const date = new Date(info.date);
      let docIdDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
      const isDST = (date) => {
        const jan = new Date(date.getFullYear(), 0, 1); // Jan 1st
        const jul = new Date(date.getFullYear(), 6, 1); // Jul 1st
        return date.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
      };
      docIdDate = docIdDate.slice(0, 12) + (isDST(date) ? '4' : '5') + docIdDate.slice(13);

      const objDate = info.date ? dayjs(info.date).utc().startOf('day').utcOffset(-5, true).format('YYYY-MM-DDTHH:mm:ssZ') : null;
      updatedCalObj.date = objDate;

      if (checkDays(dayjs(objDate))) {
        // Adding a calendar to a day that already exists
        const currentDateCalendars = await db.collection('calendars').doc(docIdDate).get()
          .then(doc => {
            const item = doc.data()
            return item.calendars
          })
        currentDateCalendars.unshift(updatedCalObj)
        updatedArr = currentDateCalendars
      } else {
        // Creating a new day and adding a calendar to it
        const newDatesArr = [{ date: objDate, day: saveData.sessionDay }, ...datesJson]
        db.collection('calendarDays').doc('1031').set({ days: newDatesArr }, { merge: true })
        updatedArr = [updatedCalObj]
      }
      db.collection('allCalendars').doc(saveData.title).set(updatedCalObj, { merge: true })
      db.collection('calendars').doc(docIdDate).set({ calendars: updatedArr }, { merge: true })
        .then(() => {
          getCalendarData();
          setOpen(false);
        })
    }
  };

  const onDelete = (saveData) => {
    try {
      const updatedCalendars = dataFromDB.filter(
        (calendar) => calendar.title !== saveData.title
      );
      db.collection('calendars').doc(new Date(datesJson[dateValue].date).toISOString()).update({
        calendars: updatedCalendars
      })

      db.collection('allCalendars').doc(saveData.title).delete().then(() => {
        setOpen(false);
      })
    } catch (error) {
      console.error("Error deleting calendar entry:", error);
    }

  }

  const onClick = (value) => {
    setDateValue(`${value}`)
  };

  const menuProps = {
    items: dateData,
    onClick,
  };
  const centerPagination = "bottomCenter";
  return (
    <div>
      <Select
        style={{ width: 180 }}
        className='calendarDropdown'
        showSearch
        placeholder='Search Calendars'
        value={dateData[dateValue]}
        optionFilterProp="label"
        onChange={onClick}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={dateData}
      />
      <Input.Search
        placeholder='Search Calendars'
        style={{ marginBottom: 8 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value)
        }}
      />
      <Table
        onRow={(record) => ({
          onClick: (event) => {
            rowClicked(event, record);
          },
        })}
        bordered
        size={'small'}
        columns={calendarsCol}
        dataSource={data}
        pagination={{
          position: [centerPagination],
          hideOnSinglePage: true,
        }}
        scroll={{ y: 500 }}
        footer={() =>
          <CalendarsModal
            info={info}
            setInfo={setInfo}
            onSubmit={onSubmit}
            open={open}
            setOpen={setOpen}
            checkDays={checkDays}
          onDelete={onDelete}
          />
        }
      />
    </div>
  );
}

export default CalendarsTable