import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Input, Select, List, Upload, DatePicker, Checkbox, Radio, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import dayjs from 'dayjs';
import '../../../App.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const { Option } = Select;

function UnconfirmedEventsModal(props) {
    const {
        info,
        setInfo,
        tableType,
        onSubmit,
        onDeleteEvent,
        // onAcceptChanges,
        open,
        setOpen,
        committeeData,
        handleDelete,
        fileList,
        handleChange,
        onRemove,
        submitting,
        setFileList,
        quillValue,
        setQuillValue,
        noDate,
        setNoDate,
        useAgenda,
        setUseAgenda,
        uponAdjourn,
        setUponAdjourn,
    }
        = props;
    const [form] = Form.useForm();
    const [adjournText, setAdjournText] = useState();
    const [acceptDisabled, setAcceptDisabled] = useState(false);
    const [billComDisabled, setBillComDisabled] = useState(false);
    const [committeeOptions, setCommitteeOptions] = useState()
    const [agendaOptions, setAgendaOptions] = useState(1)
    const [agendaWebLink, setAgendaWebLink] = useState()
    const [agendaDisabled, setAgendaDisabled] = useState(true)
    const [useTrackedSubject, setUseTrackedSubject] = useState(false)
    const [useTrackedStart, setUseTrackedStart] = useState(false)
    const [useTrackedLocation, setUseTrackedLocation] = useState(false)
    const [useTrackedLivestreamUrl, setUseTrackedLivestreamUrl] = useState(false)
    const [useTrackedAgendaLink, setUseTrackedAgendaLink] = useState(false)
    const [displayTrackedSubject, setdisplayTrackedSubject] = useState(false)
    const [displayTrackedStart, setdisplayTrackedStart] = useState(false)
    const [displayTrackedLocation, setdisplayTrackedLocation] = useState(false)
    const [displayTrackedLivestreamUrl, setdisplayTrackedLivestreamUrl] = useState(false)
    const [displayTrackedAgendaLink, setdisplayTrackedAgendaLink] = useState(false)
    const [autoUpdate, setAutoUpdate] = useState(true)
    const [notifyUsers, setNotifyUsers] = useState(true)
    const [pageUrl, setPageUrl] = useState()

    const chamberValues = {
        1: 'House',
        2: 'Senate',
    }
    const chamberOptions = [
        {
            value: 1,
            label: "House"
        },
        {
            value: 2,
            label: "Senate"
        },
    ]

    useEffect(() => {
        const committeeArr = [];
        for (let i = 0; i < committeeData?.length; i += 1) {
            committeeArr.push(<Option key={committeeData[i].id} value={`${committeeData[i].name} (${chamberValues[committeeData[i].chamber]})`}>{committeeData[i].name} ({chamberValues[committeeData[i].chamber]})</Option>)
        }
        setCommitteeOptions(committeeArr)
    }, [committeeData])

    useEffect(() => {
        if (info.key) {
            setOpen(true)
            if (info.chamber && info.chamber !== null) {
                setPageUrl(`https://www.legis.ga.gov/schedule/${chamberValues[info.chamber].toLowerCase()}/${info.id}`)
            }
            if (info.useAgenda === true) {
                setAgendaOptions(2)
                setUseAgenda(true)
                setAgendaDisabled(false)
            } else {
                setAgendaOptions(1)
                setUseAgenda(false)
                setAgendaDisabled(true)
            }
            if (info.subject !== info.trackedSubject) {
                setdisplayTrackedSubject(true)
            }
            if (info.start !== info.trackedStart) {
                setdisplayTrackedStart(true)
            }
            if (info.location !== info.trackedLocation) {
                setdisplayTrackedLocation(true)
            }
            if (info.livestreamUrl !== info.trackedLivestreamUrl) {
                setdisplayTrackedLivestreamUrl(true)
            }
            if (info.agendaLink !== info.trackedAgendaLink) {
                setdisplayTrackedAgendaLink(true)
            }
        }
    }, [info]);

    useEffect(() => {
        if (info.adminAdded === undefined) {
            setAcceptDisabled(true);
            setBillComDisabled(false);
        } else {
            setBillComDisabled(true);
            setAcceptDisabled(false);
        }
    }, [open]);

    useEffect(() => {
        form.setFieldsValue({
            ...info,
            autoUpdate: autoUpdate,
            notifyUsers: notifyUsers
        })
        setAgendaWebLink(info.agendaLink)
    }, [info, form, autoUpdate, notifyUsers]);

    const onChange = (content, delta, source, editor) => {
        setQuillValue(editor.getContents());
    };

    const onNoDateChange = (e) => {
        setNoDate(e.target.checked);
    };

    const autoUpdateChange = (e) => {
        setAutoUpdate(e.target.checked);
    };

    const notifyUsersChange = (e) => {
        setNotifyUsers(e.target.checked);
    };

    const onAdjournChange = (e) => {
        setUponAdjourn(e.target.checked);
        if (e.target.checked && !adjournText) {
            setAdjournText("Upon Adjournment");
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setUseAgenda(false)
        setUseTrackedSubject(false)
        setUseTrackedLocation(false)
        setUseTrackedLivestreamUrl(false)
        setUseTrackedAgendaLink(false)
        setFileList([]);
        setPageUrl()
    };

    const onIgnore = (id) => {
        onDeleteEvent(id)
        setPageUrl()
    };

    // const onAccept = (id) => {
    //     onAcceptChanges(id)
    //     setPageUrl()
    // };

    const onFinish = (data) => {
        onSubmit(data);
        setPageUrl()
    };

    const deleteFile = (file) => {
        handleDelete(file)
    };

    const beforeUpload = file => {
        // change to check for pdf
        if (file.type !== "application/pdf") {
            console.log(`${file.name} is not a valid image type`, 2);
            return null;
        }
        return false;
    };

    const onAgendaChange = (e) => {
        if (e.target.value === 1) {
            setAgendaDisabled(true)
            setUseAgenda(false)
        } else {
            setAgendaDisabled(false)
            setUseAgenda(true)
        }
        setAgendaOptions(e.target.value);
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }, 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'color', 'background',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return (
        <>
            <Modal
                style={{ top: 10 }}
                open={open}
                title={`Edit ${tableType}`}
                onCancel={handleCancel}
                width={800}
                footer={null}
                maskClosable={false}
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="Web Page">
                            {pageUrl ? <a href={pageUrl} target="_blank">Go to Page</a> : null}
                        </Form.Item>
                        <Form.Item label="Auto Update" name="autoUpdate">
                            <Checkbox checked={autoUpdate} onChange={autoUpdateChange} />
                        </Form.Item>
                        <Form.Item label="ID" name='id'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label="Chamber" name='chamber'>
                            <Select
                                options={chamberOptions}
                            />
                        </Form.Item>
                        <Form.Item label="Committees" name='committees'>
                            <Select
                                disabled={billComDisabled}
                                mode="multiple"
                                placeholder="Select a Committee"
                                // onChange={onCommitteeChange}
                                allowClear
                            >
                                {committeeOptions}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Subject" name='subject' style={{ marginBottom: displayTrackedSubject ? 0 : 24 }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<label style={{ color: useTrackedSubject ? 'blue' : 'red' }}>{useTrackedSubject ? 'WebCal Subject' : 'Tracked Subject'}</label>}
                            style={{ display: displayTrackedSubject ? 'block' : 'none', marginBottom: 8 }}
                        >
                            <Button
                                type="text"
                                style={{ marginTop: 0 }}
                                onClick={(e) => {
                                    setUseTrackedSubject(!useTrackedSubject)
                                    setInfo({
                                        ...form.getFieldsValue(),
                                        subject: info.trackedSubject,
                                        trackedSubject: info.subject,
                                        trackedStart: info.trackedStart,
                                        trackedLocation: info.trackedLocation,
                                        trackedLivestreamUrl: info.trackedLivestreamUrl,
                                        trackedAgendaLink: info.trackedAgendaLink,
                                        extraDocuments: info.extraDocuments,
                                    })
                                }}
                            >
                                {info.trackedSubject}
                            </Button>
                        </Form.Item>
                        <Space>
                            <Checkbox style={{ marginLeft: 147, marginBottom: 10 }} checked={noDate} onChange={onNoDateChange}>TBD</Checkbox>
                        </Space>
                        <Form.Item
                            label="Start Time"
                            style={{ marginBottom: 0 }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Form.Item
                                    name="start"
                                    style={{ flex: '1', marginBottom: displayTrackedStart ? 0 : 24 }}
                                    getValueProps={(i) => ({
                                        value: dayjs(i).utc().tz('America/New_York'), // Convert UTC to EST for display
                                    })}
                                >
                                    <DatePicker
                                        allowClear={false}
                                        placeholder='Date'
                                        showTime={noDate ? false : { use12Hours: true, format: 'hh:mm a' }}
                                        format={noDate ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm a'}
                                        placement={'bottomLeft'}
                                        onChange={(e, x) => {
                                            const newStartDate = dayjs(x, 'YYYY-MM-DD hh:mm a').tz('America/New_York', true); // Treat the input as EST
                                            const newEndDate = newStartDate.add(30, 'minute');
                                            setInfo({
                                                ...form.getFieldsValue(),
                                                start: newStartDate.utc(), // Convert to UTC for storage
                                                end: newEndDate.utc(), // Convert to UTC for storage
                                            })
                                        }}
                                    />
                                </Form.Item>
                                <Checkbox
                                    checked={uponAdjourn}
                                    onChange={onAdjournChange}
                                    style={{ marginRight: 4, display: noDate ? 'none' : 'flex' }}
                                />
                                <Form.Item
                                    name="adjournText"
                                    style={{ marginBottom: 0, flex: '1.5', marginLeft: 4, display: noDate ? 'none' : 'block' }}
                                >
                                    <Input
                                        value={adjournText}
                                        onChange={(e) => setAdjournText(e.target.value)}
                                        disabled={!uponAdjourn}
                                        placeholder="Upon Adjournment"
                                    />
                                </Form.Item>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label={<label style={{ color: useTrackedStart ? 'blue' : 'red' }}>{useTrackedStart ? 'WebCal Start' : 'Tracked Start'}</label>}
                            style={{ display: displayTrackedStart ? 'block' : 'none', marginBottom: 8 }}
                        >
                            <Button
                                type="text"
                                style={{ marginTop: 0 }}
                                onClick={(e) => {
                                    if (info.uponAdjourn) {
                                        setUponAdjourn(true)
                                    }
                                    if (info.trackedStart === null && !info.uponAdjourn) {
                                        setNoDate(true)
                                        setUponAdjourn(false)
                                    } else {
                                        setNoDate(false)
                                    }
                                    setUseTrackedStart(!useTrackedStart)
                                    setInfo({
                                        ...form.getFieldsValue(),
                                        start: info.trackedStart,
                                        trackedStart: info.start,
                                        trackedSubject: info.trackedSubject,
                                        trackedLocation: info.trackedLocation,
                                        trackedLivestreamUrl: info.trackedLivestreamUrl,
                                        trackedAgendaLink: info.trackedAgendaLink,
                                        extraDocuments: info.extraDocuments,
                                    })
                                }}
                            >
                                {info.trackedStart ? dayjs(info.trackedStart).utc().tz('America/New_York').format("YYYY-MM-DD hh:mm a") : null}
                            </Button>
                        </Form.Item>
                        <Form.Item
                            label="End Time"
                            name="end"
                            style={{ display: noDate ? 'none' : 'block' }}
                            getValueProps={(i) => ({
                                value: dayjs(i).utc().tz('America/New_York'), // Convert UTC to EST for display
                            })}
                        >
                            <DatePicker
                                allowClear={false}
                                disabled={uponAdjourn}
                                showTime={{ use12Hours: true, format: "hh:mm a" }}
                                format="YYYY-MM-DD hh:mm a"
                                placement={'bottomLeft'}
                            />
                        </Form.Item>
                        <Form.Item label="Location" name='location' style={{ marginBottom: displayTrackedLocation ? 0 : 24 }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<label style={{ color: useTrackedLocation ? 'blue' : 'red' }}>{useTrackedLocation ? 'WebCal Location' : 'Tracked Location'}</label>}
                            style={{ display: displayTrackedLocation ? 'block' : 'none' }}
                        >
                            <Button
                                type="text"
                                style={{ marginTop: 0 }}
                                onClick={(e) => {
                                    setUseTrackedLocation(!useTrackedLocation)
                                    setInfo({
                                        ...form.getFieldsValue(),
                                        location: info.trackedLocation,
                                        trackedLocation: info.location,
                                        trackedSubject: info.trackedSubject,
                                        trackedStart: info.trackedStart,
                                        trackedLivestreamUrl: info.trackedLivestreamUrl,
                                        trackedAgendaLink: info.trackedAgendaLink,
                                        extraDocuments: info.extraDocuments,
                                    })
                                }}
                            >
                                {info.trackedLocation}
                            </Button>
                        </Form.Item>
                        <Form.Item label="L-Link" name='livestreamUrl' style={{ marginBottom: displayTrackedLivestreamUrl ? 0 : 24 }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<label style={{ color: useTrackedLivestreamUrl ? 'blue' : 'red' }}>{useTrackedLivestreamUrl ? 'WebCal L-Link' : 'Tracked L-Link'}</label>}
                            style={{ display: displayTrackedLivestreamUrl ? 'block' : 'none' }}
                        >
                            <Button
                                type="text"
                                style={{ marginTop: 0 }}
                                onClick={(e) => {
                                    setUseTrackedLivestreamUrl(!useTrackedLivestreamUrl)
                                    setInfo({
                                        ...form.getFieldsValue(),
                                        livestreamUrl: info.trackedLivestreamUrl,
                                        trackedLivestreamUrl: info.livestreamUrl,
                                        trackedSubject: info.trackedSubject,
                                        trackedStart: info.trackedStart,
                                        trackedLocation: info.trackedLocation,
                                        trackedAgendaLink: info.trackedAgendaLink,
                                        extraDocuments: info.extraDocuments,
                                    })
                                }}
                            >
                                {useTrackedLivestreamUrl ? info.trackedLivestreamUrl : null}
                            </Button>
                        </Form.Item>
                        <Form.Item
                            label="Bills/Res"
                            name='billsResolutions'
                            rules={[
                                {
                                    pattern: new RegExp(/^(HB|HR|SB|SR)\d+(,\s(HB|HR|SB|SR)\d+)*$/),
                                    message: "Wrong format!"
                                },
                            ]}
                        >
                            <Input disabled={billComDisabled} onInput={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Item>
                        <Radio.Group style={{ marginLeft: 147, marginBottom: 12 }} onChange={onAgendaChange} value={agendaOptions}>
                            <Radio value={1}>Use Agenda Link</Radio>
                            <Radio value={2}>Use Agenda</Radio>
                        </Radio.Group>
                        <Form.Item label="Event Agenda" style={{ display: agendaDisabled ? 'block' : 'none' }}>
                            {agendaWebLink ? <a href={agendaWebLink} target="_blank">Go to Event Agenda</a> : null}
                        </Form.Item>
                        <Form.Item label="Agenda Link" name='agendaLink' style={{ display: agendaDisabled ? 'block' : 'none', marginBottom: displayTrackedAgendaLink ? 0 : 24 }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<label style={{ color: useTrackedAgendaLink ? 'blue' : 'red' }}>{useTrackedAgendaLink ? 'WebCal Agenda Link' : 'Tracked Agenda Link'}</label>}
                            style={{ display: displayTrackedAgendaLink ? 'block' : 'none' }}
                        >
                            <Button
                                type="text"
                                style={{ marginTop: 0 }}
                                onClick={(e) => {
                                    setAgendaDisabled(true)
                                    setUseAgenda(!useAgenda)
                                    setAgendaOptions(1)
                                    setUseTrackedAgendaLink(!useTrackedAgendaLink)
                                    setInfo({
                                        ...form.getFieldsValue(),
                                        agendaLink: info.trackedAgendaLink,
                                        trackedAgendaLink: info.agendaLink,
                                        trackedSubject: info.trackedSubject,
                                        trackedStart: info.trackedStart,
                                        trackedLocation: info.trackedLocation,
                                        trackedLivestreamUrl: info.trackedLivestreamUrl,
                                        extraDocuments: info.extraDocuments,
                                    })
                                }}
                            >
                                {useTrackedAgendaLink ? info.trackedAgendaLink : null}
                            </Button>
                        </Form.Item>
                        <Form.Item label="Agenda" name='agenda' style={{ display: agendaDisabled ? 'none' : 'block' }} >
                            <ReactQuill
                                style={{ height: 280 }}
                                theme="snow"
                                value={quillValue}
                                onChange={onChange}
                                modules={modules}
                                formats={formats}
                            />
                        </Form.Item>
                        <Form.Item label="Extra Documents" style={{ marginTop: 50 }}>
                            <div style={{ border: '1px solid #d9d9d9', borderRadius: '6px', padding: 3 }}>
                                <Upload
                                    fileList={fileList}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                    onRemove={onRemove}
                                    multiple={true}
                                >
                                    <Button disabled={submitting ? true : false} icon={<UploadOutlined />}>{submitting ? "Uploading" : "Upload"}</Button>
                                </Upload>
                                <List
                                    style={{ marginTop: 10 }}
                                    bordered
                                    dataSource={info.extraDocuments}
                                    renderItem={(item) =>
                                        <List.Item key={item.name} actions={[<Button onClick={() => deleteFile(item.name)}>Delete</Button>]}>
                                            {item.name}
                                        </List.Item>
                                    }
                                />
                            </div>
                        </Form.Item>
                        <Form.Item label="Notify Users" name="notifyUsers">
                            <Checkbox checked={notifyUsers} onChange={notifyUsersChange} />
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 130 }}>
                            {/* <Button disabled={acceptDisabled} style={{ width: 210 }} onClick={() => onAccept(info.id)}>
                                Accept Changes
                            </Button> */}
                            <Button type='primary' htmlType='submit' style={{ width: 200 }}>
                                Send to Tracked Events
                            </Button>
                            <Button style={{ marginLeft: 10, width: 200 }} onClick={() => onIgnore(info.id)}>
                                Ignore Changes
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default UnconfirmedEventsModal
