import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, InputNumber, Select } from 'antd';
import '../../../App.css';

function UsersModal({ info, onSubmit, onDelete, open, setOpen }) {
    const [form] = Form.useForm();
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        form.setFieldsValue(info);
    }, [info, form]);

    const showModal = () => {
        setIsDisabled(false);
        form.setFieldsValue({
            firstName: '',
            lastName: '',
            email: '',
            tier: '',
            phone: '',
            role: 'user',
            loggedInDevices: 0,
            
        });
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const onFinish = (data) => {
        onSubmit(data);
    };

    const handleDelete = () => {
        onDelete();
    };

    // Add a notifications enabled section to the modal somewhere
    // If a customer calls saying they arent getting notifications, we can 
    // check this to see if they allowed the app to push notifications to their device
    // This will be based on if there are push tokes present
    // Might add how many devices are recieving them based on array length
    return (
        <>
            <Button type="primary" onClick={showModal}>
                New Entry
            </Button>
            <Modal
                open={open}
                title={'Edit User'}
                onCancel={handleCancel}
                width={800}
                footer={null}
                style={{ top: 20 }} // Adjust the value to move the modal higher or lower
            >
                <div>
                    <Form
                        layout={'horizontal'}
                        form={form}
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 20 }}
                        style={{
                            maxWidth: 700,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="Email" name='email'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label="First Name" name='firstName'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Last Name" name='lastName'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Tier" name='tier'>
                            <Select
                                options={[
                                    { value: '1', label: '1 (State Agency Tracking)' },
                                    { value: '2', label: '2 (Legislative Tracking)' },
                                    { value: '3', label: '3 (All-Inclusive Membership)' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label="Phone" name='phone'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Organization" name='organization'>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Google Calendars" name='googleCalendarsEnabled'>
                            <Select
                                disabled={true}
                                options={[
                                    { value: true, label: 'Enabled' },
                                    { value: false, label: 'Disabled' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label="Logged In Devices" name='loggedInDevices'>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item style={{ marginLeft: 120 }}>
                            <Button block type='primary' htmlType='submit'>
                                Save User
                            </Button>
                        </Form.Item>
                        {/* <Form.Item style={{ marginLeft: 120 }}>
                            <Button block danger onClick={handleDelete}>
                                Delete User
                            </Button>
                        </Form.Item> */}
                    </Form>
                </div>
            </Modal>
        </>
    );
}

export default UsersModal;